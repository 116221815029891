import { HttpInterceptorFn } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { CommonService } from '../../shared/services/common.service';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUser } from '../../modules/auth/state/auth.state';
import { AuthenticationService } from '../services/authentication.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  const commonSvc = inject(CommonService);
  const authenticationSvc = inject(AuthenticationService);
  store.select(selectUser).subscribe(user => {
    if (user) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${user.token}`,
        },
      });
    }
  });
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 0) {
        commonSvc.message.error('Server is down or unreachable.');
      } else if (error.status === 401 || error.status === 403) {
        commonSvc.message.error('Unauthorized request. Please log in again.');
        authenticationSvc.onLogout();
      }
      else if(error.status === 406){
        commonSvc.message.error('Virus detected in uploaded file.');
      }
      else {
        console.log(`HTTP Error: ${error.status} - ${error.message}`);
        // commonSvc.message.error(`Something went wrong please contact support.`, { nzDuration: 4000, nzPauseOnHover: true });
      }
      return throwError(() => error);
    })
  );
};
