<nz-layout>
    <nz-header class="border-none sticky top-0 w-full z-50">
        <app-header/>
    </nz-header>
    <nz-content>
        <!-- <div class="mx-5 my-2">
            <nz-breadcrumb [nzSeparator]="'>'">
                <ng-container *ngFor="let item of locationData">
                    <nz-breadcrumb-item class="cursor-pointer hover:text-gray-900">
                        <i nz-icon nzType="home" nzTheme="outline" class="mr-2"></i>
                        <span>{{item.title}}</span>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item [nzOverlay]="menu" class="cursor-pointer">
                        {{item['children'][0].title}}
                    </nz-breadcrumb-item>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu nzSelectable>
                            <li nz-menu-item *ngFor="let child of item['children']">
                                {{child['title']}}
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </ng-container>
            </nz-breadcrumb>
        </div> -->
        <div class="m-3">
            <router-outlet></router-outlet>
        </div>
    </nz-content>
</nz-layout>