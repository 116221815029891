import { Component, inject, OnInit } from '@angular/core';
import { CommonService } from '../../shared/services/common.service';
import { selectTreeLocationStructure } from '../../modules/location-tree/state/location-tree.state';
import { distinctUntilChanged, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent implements OnInit {
  drawer: boolean = true;
  commonSvc = inject(CommonService);
  locationData!: any;

  ngOnInit(): void {
    this.commonSvc.store.select(selectTreeLocationStructure).pipe(
      take(1),
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe((data) => {
      this.locationData = data;
    })
  }
}
