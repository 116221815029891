import { Component, OnDestroy } from '@angular/core';
import { TabManagerService } from './core/services/tab-manager.service';
import { Store } from '@ngrx/store';
import { appState} from './state/app.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  constructor(private tabManagerService: TabManagerService, private store: Store<appState>) { }

  ngOnInit(): void { }
  ngOnDestroy(): void {
    this.tabManagerService.cleanupOnClose();
  }
}
