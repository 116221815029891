import { ActionReducer, Action, MetaReducer, INIT } from '@ngrx/store';
import * as LZString from 'lz-string';

// Compress the data using lz-string (UTF16 format)
export function simpleCompress(data: string): string {
    return LZString.compressToUTF16(data);
}

// Decompress the data using lz-string (UTF16 format)
export function simpleDecompress(compressedData: string): string {
    return LZString.decompressFromUTF16(compressedData);
}

export function createLocalStorageSyncReducer<T>(
    key: string,
    sliceKey: keyof T
): MetaReducer<T> {
    return (reducer: ActionReducer<T>): ActionReducer<T> => {
        return (state: T | undefined, action: Action): T => {
            // Compute the next state first (ensures initial state is available)
            const nextState = reducer(state, action);
            // Restore state on INIT if there's saved data
            if (action.type === INIT) {
                const savedState = localStorage.getItem(key);
                if (savedState) {
                    try {
                        const decompressedState = simpleDecompress(savedState);
                        const restoredState = JSON.parse(decompressedState);
                        // console.log(`[MetaReducer] Restored state for ${String(sliceKey)}:`, restoredState);
                        return {
                            ...nextState, // Use the computed nextState instead of raw state
                            [sliceKey]: restoredState,
                        };
                    } catch (error) {
                        console.error(`[MetaReducer] Error restoring state for ${String(sliceKey)}:`, error);
                    }
                }
                return nextState; // Return nextState if no saved state
            }
            // Save to localStorage if the slice changes
            if (state && nextState[sliceKey] !== state[sliceKey]) {
                try {
                    const stateSlice = nextState[sliceKey];
                    if (stateSlice !== undefined && stateSlice !== null) {
                        const compressedState = simpleCompress(JSON.stringify(stateSlice));
                        localStorage.setItem(key, compressedState);
                        // console.log(`[MetaReducer] Saved state for ${String(sliceKey)}:`, stateSlice);
                    }
                } catch (error) {
                    console.error(`[MetaReducer] Error saving state for ${String(sliceKey)}:`, error);
                }
            }
            return nextState;
        };
    };
}