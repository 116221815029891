import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { FullScreenLayoutComponent } from './layouts/full-screen-layout/full-screen-layout.component';
import { authGuard } from './core/guards/auth.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { MaxTabsLimitExceededComponent } from './shared/components/info-page/info-page.component';
import { GetSupportComponent } from './modules/profile/get-support/get-support.component';
import { noAuthGuard } from './core/guards/no-auth.guard';

const routes: Routes = [
  // Redirect root to dashboard
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },

  // Standalone public routes
  {
    path: 'login-get-support',
    component: GetSupportComponent
  },
  {
    path: 'max-tabs-limit-exceeded',
    component: MaxTabsLimitExceededComponent
  },

  // Protected Main Layout
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/main-layout/main-layout.module').then(m => m.MainLayoutModule)
      }
    ]
  },
  {
    path: '',
    component: FullScreenLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/full-screen-layout/full-screen-layout.module').then(m => m.FullScreenLayoutModules)
      }
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent // Page not found 404
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    urlUpdateStrategy: 'deferred',
    useHash: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
