import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class TabManagerService {
  private tabId = `${Math.random()}`.slice(2, 11);
  private readonly TAB_LIMIT = 3;
  private activeTabs: Set<string> = new Set();
  private broadcastChannel: BroadcastChannel;

  constructor(
    private router: Router,
    private store: Store
  ) {
    this.broadcastChannel = new BroadcastChannel('tab_management');
    this.initializeBroadcastChannel();
    this.registerTab();
    window.addEventListener('beforeunload', this.cleanupOnClose.bind(this));
  }

  private initializeBroadcastChannel(): void {
    this.broadcastChannel.onmessage = ({ data }) => {
      const { type, tabId } = data;
      switch (type) {
        case 'tab_opened':
          this.activeTabs.add(tabId);
          break;
        case 'tab_closed':
          this.activeTabs.delete(tabId);
          break;
        case 'request_active_tabs':
          this.broadcastChannel.postMessage({
            type: 'tab_opened',
            tabId: this.tabId,
          });
          break;
        case 'logout':
          if (this.activeTabs.size > 1) {
            this.router.navigate(['/auth/login'], { queryParams: { tabReload: true } });
          }
          break;
      }
    };
    this.requestActiveTabs();
  }
  private requestActiveTabs(): void {
    setTimeout(() => {
      this.broadcastChannel.postMessage({
        type: 'request_active_tabs',
        tabId: this.tabId,
      });
    }, 100);
  }

  private registerTab(): void {
    this.activeTabs.add(this.tabId);
    this.broadcastChannel.postMessage({
      type: 'tab_opened',
      tabId: this.tabId,
    });
    setTimeout(() => {
      if (this.isTabLimitExceeded()) {
        this.router.navigate(['/max-tabs-limit-exceeded']);
      }
    }, 200);
  }

  public isTabLimitExceeded(): boolean {
    return this.activeTabs.size > this.TAB_LIMIT;
  }
  public cleanupOnClose(): void {
    this.activeTabs.delete(this.tabId);
    this.broadcastChannel.postMessage({
      type: 'tab_closed',
      tabId: this.tabId,
    });
    this.broadcastChannel.close();
  }
}
