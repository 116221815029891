import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export const authGuard: CanActivateFn = () => {
  const authenticationSvc = inject(AuthenticationService);
  const router = inject(Router);
  if (authenticationSvc.isAuthenticated()) {
    return true;
  } else {
    router.navigate(['auth/login']);
    return false;
  }
};
